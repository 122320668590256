<template>
    <div style="border: 1px solid black; margin-bottom: 10px; min-height: 20px">
        <whereby-embed style="height: 600px" room="https://arena-cx.whereby.com/acx-portal-sdkxjv?roomKey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZWV0aW5nSWQiOiI1NDIyMTAwNCIsInJvb21SZWZlcmVuY2UiOnsicm9vbU5hbWUiOiIvYWN4LXBvcnRhbC1zZGt4anYiLCJvcmdhbml6YXRpb25JZCI6IjE1Mjg4NCJ9LCJpc3MiOiJodHRwczovL2FjY291bnRzLnNydi53aGVyZWJ5LmNvbSIsImlhdCI6MTY1MDA2MTkyNCwicm9vbUtleVR5cGUiOiJtZWV0aW5nSG9zdCJ9.wrALKuEWHGmzrzJYMsGmIZHcwkExmdkk-HThXnBm-QI" displayName="Thomas Kelleher" audio="on" video="on" chat="on"></whereby-embed>
    </div>
</template>

<script>
export default {
    created() {
        if (document.getElementById('wherebyMeetingComponent')) return;
        var scriptTag = document.createElement('script');
        scriptTag.src = 'https://cdn.srv.whereby.com/embed/v1.js';
        scriptTag.id = 'wherebyMeetingComponent';
        scriptTag.type = 'module';
        document.getElementsByTagName('head')[0].appendChild(scriptTag);
    },

    mounted() {},
};
</script>

<style scoped>

    .layout-wrapper .layout-main .layout-content
    {
        padding: 0px;
        padding-top: 0px;
    }

</style>
